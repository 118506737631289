<template>
  <section class="publications">
        <h2>Diploma thesis</h2>

        <h3>Graphical user interfaces in videogames</h3>
        <p>In a nutshell, my study examines the graphical user interfaces. Not by appearance, rather from the perspective of their functions. At the beginning of my study, the reader becomes familiar with the concept of the user interfaces. Then after a short historical overview of the “Heads-Up Display” (briefly HUD), we can get further knowledge about this topic. After the discussion of the HUD is essential to have a word from every game's heart: the game engine.</p>
        <p>My thesis aims to examine the unusual aspects of the graphical user interface elements. To do this, some basic concepts need to be cleared first, so we can learn about the fourth wall in video games. After that, we'll examine two unusual aspects of the user interface's structure. Thus, I declare four classes of the representation, through the elements actions and spatial relationships. In order to fully understand these mechanisms I will demonstrate each one of them with the game entitled “Left 4 Dead 2” (Valve Software, 2009).</p>
        <p>At the end of my study, I want to share my opinions about the most disturbing phenomenon in today's games. Then I'm closing my thesis with thinking about how can we use our gained experience and knowledge about user interfaces, and where and when can they utilized outside game development.</p>
        <a href="/downloads/hodvogner_daniel_szakdolgozat.pdf" target="_blank">Read the whole thesis (in hungarian)</a>
  </section>
</template>

<script>
export default {

}
</script>

<style>
.publications {
    color: white;

    margin: 3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

@media screen and (max-width: 600px) {
  .publications {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>