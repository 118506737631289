<template>
  <section class="contact">
    <div>
        Try to get in touch? <br>
        <a href="https://www.linkedin.com/in/dhodvogner/">Reach out to me on LinkedIn</a>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
.contact {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    color: white;    
    height: 50vh;
    text-align: center;
}
</style>