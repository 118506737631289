<template>
  <section>
    <div class="casestudies">
      <div class="deco-top">
          <div class="deco-top-text">Case Study</div>
      </div>
      <div class="deco-bottom">
          <div class="deco-bottom-text">
            IBM IT Provisioning tool
              <!--<a href="https://www.behance.net/gallery/143166031/IBM-ITP" target="_blank">Learn more on behance</a>-->
          </div>
      </div>
    </div>
    <div class="casestudies-text">
      <!--<h2>IBM ITP</h2>-->
      <p>
        The IT Provisioning tool (ITP) is an internal HR tool, which helps the various HR teams to process a 
        new IBM employee's post hire actions. These include reviewing and processing their badge picture, 
        ordering and shipping their laptops to their work location, organize travel when it necessary. 
        The tool was originally created in a business process management framework, which was quite limited. 
        When the tool had to transition over to the Cloud, our propose was to transform its user experience and user interface entirely.
      </p>
      <a href="https://www.behance.net/gallery/143166031/IBM-ITP" target="_blank">Learn more on Behance</a>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
.casestudies-text {
  margin: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 600px) {
  .casestudies-text {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.casestudies {
    display: block;
    overflow: hidden;
    height: 80vh;

    color: white;
    font-family: 'Chango', cursive;
    
    text-align: center;

    background-image: url('~@/assets/ibm-itp.png');
    background-repeat: no-repeat;
    background-position: 100% 10%;
    background-size: cover;
    background-color: black;
}

.casestudies .deco-bottom {
    position: relative;
    top: 0%;
    left: -5%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    width: 120vw;
    height: 50vh;
    transform: rotate(-5deg) translateY(10vh);
    background-color: black;
}

.casestudies .deco-bottom-text {
    margin-top: 3%;
    margin-right: 15%;
    font-size: max(3vw, 29px);
    text-shadow: 0px -5px black;
}

.casestudies .deco-top {
    position: relative;
    top: -60%;
    left: -5%;

    display: flex;
    align-items: flex-end;

    width: 120vw;
    height: 50vh;
    transform: rotate(-5deg) translateY(10vh);
    background-color: black;
}

.casestudies .deco-top-text {
    margin-left: 5%;
    margin-bottom: -2%;
    font-size: max(7vw, 50px);
    text-shadow: 0px 5px black;
}

@media screen and (max-width: 600px) {
    .casestudies .deco-top {
        left: -11%;
    }

    .casestudies .deco-top-text {
        position: relative;
        top: 2%;
        left: 1%;
    }

    .casestudies .deco-bottom-text {
        position: relative;
        top: 4%;
        left: -3%;
    }
}   
</style>