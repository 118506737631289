<template>
  <section class="photos">
    <div class="deco-top">
        <div class="deco-top-text">Photography</div>
    </div>
    <div class="deco-bottom">
        <div class="deco-bottom-text">
            <a href="https://www.instagram.com/hodiography/" target="_blank">See more on instagram</a>
        </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
.photos {
    display: block;
    overflow: hidden;
    height: 80vh;

    color: white;
    font-family: 'Chango', cursive;
    
    text-align: center;

    background-image: url('~@/assets/photos.png');
    background-repeat: no-repeat;
    background-position: 100% 10%;
    background-size: cover;
    background-color: black;
}

.photos .deco-bottom {
    position: relative;
    top: 0%;
    left: -5%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    width: 120vw;
    height: 50vh;
    transform: rotate(-5deg) translateY(10vh);
    background-color: black;
}

.photos .deco-bottom-text {
    margin-top: 3%;
    margin-right: 15%;
    font-size: max(3vw, 29px);
    text-shadow: 0px -5px black;
}

.photos .deco-top {
    position: relative;
    top: -60%;
    left: -5%;

    display: flex;
    align-items: flex-end;

    width: 120vw;
    height: 50vh;
    transform: rotate(-5deg) translateY(10vh);
    background-color: black;
}

.photos .deco-top-text {
    margin-left: 5%;
    margin-bottom: -2%;
    font-size: max(7vw, 42px);
    text-shadow: 0px 5px black;
}

@media screen and (max-width: 600px) {
    .photos .deco-top {
        left: -11%;
    }

    .photos .deco-top-text {
        position: relative;
        top: 2%;
    }

    .photos .deco-bottom-text {
        position: relative;
        top: -7%;
        left: 3%;
    }
}   
</style>