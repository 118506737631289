<template>
  <section class="work-experience">
        <h2>Work Experience</h2>

        <div class="experience">
            <div class="date">
                <h2>Now</h2>
            </div>
            <div class="content">
                <h3>Iteration Manager @ IBM CIO</h3>
                <small>Dec 2022 to Present</small>
                <p>As an iteration manager I’m directly responsible for a team of 10 and 3 internal facing application. Besides facilitating the Agile ceremonies of the team and organising their daily workload, I’m directly involved in many business decisions reading the applications what my team maintains.</p>
                <p>My team responsible for one of IBM main HR application, what is handling all IBM employees around the globe.</p>
            </div>
        </div>

        <div class="experience">
            <div class="date">
                <h2>2021</h2>
            </div>
            <div class="content">
                <h3>UX Designer @ IBM Cloud</h3>
                <small>April 2021 to Dec 2022</small>
                <p>As a UX Designer in IBM Cloud I had the opportunity to contribute on multiple client facing products of IBM. For example a hybrid-multi-cloud solution called IBM Satellite and a serverless platform called IBM Code Engine.</p>
            </div>
        </div>


        <div class="experience">
            <div class="date">
                <h2>2018</h2>
            </div>
            <div class="content">
                <h3>UX Designer @ IBM CIO</h3>
                <small>March 2018 to April 2021</small>
                <p>As part of IBM's internal informatics, I was able to design various B2B tools, for example, one what HR uses
                    to manage all IBM applicant's after their hiring process. Providing them with internal access, emails,
                    badges, workstations.</p>
            </div>
        </div>


        <div class="experience">
            <div class="date">
                <h2>2017</h2>
            </div>
            <div class="content">
                <h3>Junior Software Engineer @ Epam Systems</h3>
                <small>August 2017 to February 2018</small>
                <p>I joined EPAM as a Junior Software Engineer to catch up with the latest web-related technologies and trends,
                    like single-page applications, node js, etc. I worked on an American bank's hedge fund management system.
                </p>
            </div>
        </div>


        <div class="experience">
            <div class="date">
                <h2>2013</h2>
            </div>
            <div class="content">
                <h3>UI Designer @ Gameloft</h3>
                <small>October 2013 to August 2017</small>
                <p>I worked on Dragon Mania Legends one of the still top-grossing games of Gameloft as of today. My tasks were
                    creating low and high-fidelity screen mock-ups in Photoshop then programming them in Flash. We worked in an
                    agile manner and used Jira and Confluence to track our progress.</p>
            </div>
        </div> 

        <h2>Education</h2>

        <div class="experience">
            <div class="date">
                <h2>2013</h2>
            </div>
            <div class="content">
                <h3>Media Design BA @ Budapest Metropolitan University</h3>
                <small>September 2010 to June 2013</small>
                <p>
                    I believe media design was a really great choice because I could explore and experience many different areas. For example, I was able to get some insight into movie making and special effects, electronics, 3D graphics, programming, and music. But primarily, I fell in love with the subject at that time called "web design," thanks to my highly inspiring teacher Gusztáv Nikházy. And I am happy that growing up, I was able to turn this love into my career.
                </p>
            </div>
        </div>

        <div class="experience">
            <div class="date">
                <h2>2008</h2>
            </div>
            <div class="content">
                <h3>Traditional Animation Training @ Magyar Rajzfilm </h3>
                <small>January 2008 to December 2008</small>
                <p>
                    One of my childhood dreams was to make cartoons. This long-standing love inspired me to join a course during my secondary school years. During this course, we learned basic anatomy, the fundamentals of motion and animation, and the traditional key-frame animation technique. It may not seems so relevant, but I firmly believe this course serves as the foundation for my future interests, and I'm really proud that I was able to finish it at the age of 18 besides school.
                </p>
            </div>
        </div>

  </section>
</template>

<script>
export default {

}
</script>

<style>
.work-experience {
    color: white;

    margin: 3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.experience {
    display: grid;
    grid-template: "date content";
    gap: 5rem;

    margin-left: 5rem;
    margin-right: 5rem;
}

.date {
    grid-area: date;
}

.content {
    grid-area: content;

}

@media screen and (max-width: 600px) {
    .work-experience {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .experience {
        gap: 0;
        margin: 0;
    }

    .date {
        display: none;
    }
}
</style>