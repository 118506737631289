<template>
  <section class="tools-of-trade">
    <h2>How I work</h2>

    <h3>I. Design problem</h3>
    <p>
      My design process usually starts with a design problem, it is typically a need that comes from a client (or OM, PM), but there could be other sources like existing studies, usability tests, etc. A design problem is usually a new feature/functionality we want to introduce to a product, an existing user need, or a pain point we like to solve or satisfy.

      <br><br>
      <b>Tools I use:</b> Meetings online or in-person.
    </p>

    <h3>II. Research</h3>
    <p>
      Research is the most essential tool for exploring a problem more in-depth and keeping the human element focused. There are multiple ways to do it, but it usually includes tools like Competitor analysis, User interviews, writing hill/problem statements, creating As-is and To-be scenario mapping and collection inspirations, best practices.

      <br><br>
      <b>Tools I use:</b> Whiteboard, Markers, and a lot of Post-its. (Or Mural/Figma Jam)
    </p>

    <h3>III. Ideate and Iterate</h3>
    <p>
      Ideation is an experimental stage where I try to create possible solutions to the aforementioned design problem. In this stage, I use what I learned during the research phase and try quickly produce multiple solutions. I'd like to include the client(s) or possibly sponsor users already in this stage to make sure we can agree on the direction of heading as soon as possible. The goal in this stage is not to get every detail perfectly but to create the overall screen flows, plan the interactions and behaviors, create the basics of the information architecture, and ensure that our intentions align with our set goals.
      
      <br><br>
      <b>Tools I use:</b> Pen and Paper, Low fidelity wireframes
    </p>

    <h3>IV. Polish</h3>

    <p>
      After we agree on the big picture, it's time to get the little details done. This includes things like error states, empty states, or complex interactions or behaviors. In this phase, I found it very useful to also involve the developers - besides the clients and sponsor users. Their insights can be beneficial moving forward and usually lead to a smooth handover. User testing is another helpful tool, primarily when designing more complex flows or interactions. Here we make sure that our ideas resonate with our end-users and they can follow our intentions.

      <br><br>
      <b>Tools I use:</b> Low and Mid fidelity wireframes, interactive prototypes
    </p>

    <h3>V. Finalise and Handover</h3>
    <p>
      As of last touches, I increase the visual fidelity of the final deliverables, or I work with a visual designer to make sure all the colors, elements, margins, etc., match the corporate's design system. We rarely change the functionality at this stage, but we are more focused on aesthetics. This phase usually ends with the developer handover, where we move the design deliverables to the development team. I believe it's essential to work closely with the developers in this stage to make sure their implementation matches our designs and to be available to answer any questions they might have.

      <br><br>
      <b>Tools I use:</b> High fidelity wireframes, handover documents
    </p>

    <h3>VI. Observe ∞</h3>
    <p>
      Ideally, the designer's work would be concluded at this stage, and we could move on to the next design problem. But I found it quite helpful to follow along with our freshly released designs and - if possible - set up some measurements to track how they perform in the "wild." It can be satisfying to see that our end-users are happy with our work. But, on the other hand, we can learn important lessons that we may miss or misunderstand, opening new opportunities for future improvements.
    </p>


  <h2>Softwares I use</h2>

    <ul>
      <li>UI/UX Design: Figma, Sketch, Adobe XD</li>
      <li>Prototype: Figma, inVision</li>
      <li>Motion Design: Adobe After Effects, Adobe Animate</li>
      <li>Vector graphics: Affinity Designer, Adobe Illustrator</li>
      <li>Drawing: Procreate, Adobe Photoshop</li>
      <li>Photography: Adobe Lightroom</li>
      <li>Programming: VS Code</li>
      <li>Programming languages: HTML/CSS, Javascript, Typescript</li>
    </ul>
  </section>
</template>

<script>
export default {

}
</script>

<style>
.tools-of-trade {
  margin: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 600px) {
  .tools-of-trade {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>