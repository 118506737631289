<template>
  <main id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Introduction />
    <WorkExperience />
    <CaseStudies />
    <ToolsOfTrade />
    <ArtStuff />
    <Other />
    <Photos />
    <Contact />
    <footer>2024 - Daniel Hodvogner</footer>
  </main>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Introduction from './components/Introduction.vue'
import WorkExperience from './components/WorkExperience.vue'
import ArtStuff from './components/ArtStuff.vue'
import Photos from './components/Photos.vue'
import CaseStudies from './components/CaseStudies.vue'
import Other from './components/Other.vue'
import Contact from './components/Contact.vue'
import ToolsOfTrade from './components/ToolsOfTrade.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld,
    Introduction,
    WorkExperience,
    ArtStuff,
    Photos,
    CaseStudies,
    Other,
    Contact,
    ToolsOfTrade
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Chango&family=Sigmar+One&&display=swap');

html, body {
  margin: 0;
  padding: 0;
  background-color: black;
  margin-top: 1rem;
}

h2 {
    font-family: 'Chango', cursive;
    text-shadow: 0px 5px rgba(255, 255, 255, .15);
}

h3 {
    margin-bottom: 0.25rem;
}

a {
  color: white;
}

a:hover {
  color: rgba(255, 255, 255, .75)
}

footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: black;
}
</style>
